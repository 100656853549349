<template>
  <div>
    <el-row class="bgW">
      <el-col :span="16">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">

          <el-form-item label="日期">
            <el-date-picker v-model="search.start_time" type="date" class="w130" placeholder="开始日期"
              value-format="yyyy-MM-dd"></el-date-picker>
            <el-date-picker v-model="search.end_time" type="date" class="w130" placeholder="结束日期"
              value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>
          <el-form-item label="门店类型" prop="type">
            <el-select v-model="search.type" class="w100" clearable placeholder="门店类型">
              <el-option v-for="item in options.type" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>

        </el-form>
      </el-col>
      <el-col :span="8" class="btnBox">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
        <el-button type="primary" class="mb10" size="mini" @click="taskFrame = true">添加任务</el-button>
        <el-button type="primary" class="mb10" size="mini" @click="taskFrame2 = true">导入任务</el-button>
        <el-button type="primary" class="mb10" size="mini" @click="getExcel">任务模板</el-button>
        <el-button type="primary" class="mb10" size="mini" :loading="exportS" @click="exportExcel">导出数据</el-button>
      </el-col>
    </el-row>

    <!--列表-->
    <el-row>
      <el-col :span="24">
        <el-table :data="tableData" size="small"  max-height="750" border v-loading="loading" :cell-style="cellStyle"
          :span-method="objectSpanMethod" :header-cell-style="rowClass">
          <ElTableColumn label="市场名称" prop="marketName" />
          <ElTableColumn label="店面名称" prop="shopName" />
          <ElTableColumn label="实际完成">
            <el-table-column label="实收款" :render-header="renderHeader">
              <template slot-scope="{ row }">
                <div class="hand" @click="clickBtn(row, '1')">{{ row.amount }}</div>
              </template>
            </el-table-column>

            <el-table-column label="转诊" :render-header="renderHeader">
              <template slot-scope="{ row }">
                <div class="hand" @click="clickBtn(row, '2')">{{ row.referralMoney }}</div>
              </template>
            </el-table-column>

			<!-- <el-table-column label="转诊" :render-header="renderHeader">
			  <template slot-scope="{ row }">{{ row.referralMoney }}</template>
			</el-table-column> -->

            <el-table-column label="疤痕" :render-header="renderHeader">
              <template slot-scope="{ row }">
                <div class="hand" @click="clickBtn(row, '3')">{{ row.scarMoney }}</div>
              </template>
            </el-table-column>

            <el-table-column label="皮肤项目" :render-header="renderHeader">
              <template slot-scope="{ row }">
                <div class="hand" @click="clickBtn(row, '4')">{{ row.skinItem }}</div>
              </template>
            </el-table-column>

            <el-table-column label="身体项目" :render-header="renderHeader">
              <template slot-scope="{ row }">
                <div class="hand" @click="clickBtn(row, '5')">{{ row.physicalEvent }}</div>
              </template>
            </el-table-column>

            <!-- <el-table-column label="项目合计" :render-header="renderHeader">
              <template slot-scope="{ row }">
                <div class="hand" @click="clickBtn(row, '6')">{{ row.projectMoney }}</div>
              </template>
            </el-table-column> -->

            <el-table-column label="青春痘" :render-header="renderHeader">
              <template slot-scope="{ row }">
                <div class="hand" @click="clickBtn(row, '7')">{{ row.qcdMoney }}</div>
              </template>
            </el-table-column>

            <el-table-column label="护肤品" :render-header="renderHeader">
              <template slot-scope="{ row }">
                <div class="hand" @click="clickBtn(row, '8')">{{ row.hfpMoney }}</div>
              </template>
            </el-table-column>

            <el-table-column label="业绩合计" prop="achievementMoney">
            </el-table-column>
          </ElTableColumn>
          <ElTableColumn label="店面任务" prop="shopTask" />
          <ElTableColumn label="店面达成率" prop="shopReachRate" />
          <ElTableColumn label="店面任务(基础)" prop="shopTaskBase" v-if="!userInfo.shop_id" />
          <ElTableColumn label="店面达成率(基础)" prop="shopReachRateBase" v-if="!userInfo.shop_id" />
          <ElTableColumn label="市场任务" prop="marketTask" v-if="!userInfo.shop_id" />
          <ElTableColumn label="市场达成" prop="marketReach" v-if="!userInfo.shop_id" />
          <ElTableColumn label="市场达成率" prop="marketReachRate" v-if="!userInfo.shop_id" />
          <ElTableColumn label="市场任务(基础)" prop="marketTaskBase" v-if="!userInfo.shop_id" />
          <ElTableColumn label="市场达成率(基础)" prop="marketReachRateBase" v-if="!userInfo.shop_id" />

        </el-table>
        <div class="reveal">
          <span class="mr20">时间达成率：<span class="peril">{{ timeReachRate }}%</span></span>
          <span>店面数量：<span class="peril">{{ shopCount }}</span></span>
        </div>
        <!-- <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          /> -->
      </el-col>
    </el-row>
    <!-- 新增弹框 -->
    <el-dialog title="新增月度业绩任务" :visible.sync="taskFrame" :close-on-click-modal="false" width="30%" @close="closeTask">
      <el-form ref="taskDetail" label-position="right" label-width="120px" :rules="rules" :model="taskDetail"
        class="frame" size="mini">
        <el-form-item label="店铺名称:" prop="shopId">
          <el-select v-model="taskDetail.shopId" clearable remote filterable v-loading="loading" class="w300"
            @change="selectShop" :remote-method="remoteShop" placeholder="请输入店铺名称">
            <el-option v-for="item in options.shopId" :key="item.shopId" :label="item.shopName" :value="item.shopId" />
          </el-select>
        </el-form-item>
        <el-form-item label="任务金额" prop="price">
          <el-input placeholder="请输入任务金额" class="w300" v-model="taskDetail.price">

            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="年/月" prop="month">
          <el-date-picker v-model="taskDetail.month" type="month" class="w300" value-format="yyyy-MM"
            placeholder="选择月份">
          </el-date-picker>
        </el-form-item>
        <div class="footerBtn mt50">
          <el-button type="primary" size="mini" class="mainBtn" @click="submitForm('taskDetail')">完成，提交</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog title="新增月度业绩任务" :visible.sync="taskFrame2" :close-on-click-modal="false" width="30%" @close="closeTask">
      <el-form ref="taskExcel" label-position="right" label-width="120px" :model="taskExcel" class="frame" size="mini">
        <el-form-item label="文件" prop="file">
          <input type="file" class="w300" @change="handleFileChange">
        </el-form-item>
        <el-form-item label="年/月" prop="month">
          <el-date-picker v-model="taskExcel.date" type="month" class="w300" value-format="yyyy-MM" placeholder="选择月份">
          </el-date-picker>
        </el-form-item>
        <div class="footerBtn mt50">
          <el-button type="primary" size="mini" class="mainBtn" @click="importFile('taskExcel')">完成，提交</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getLastDay } from '@/utils';
import { mapGetters } from "vuex";
import pagination from '@/components/Pagination'
import { getShopList } from "@/api/shop";
import { handleExportExcel } from '@/vendor/handleExportExcel';
import { readAdminShopAchievementStatistics, shopTaskS, importShopAchievementExcel } from "@/api/statistics";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  start_time = "";
  end_time = "";
  type = "";
}
class Options {
  shopId = [];
  type = [
    {
      id: "1",
      name: "直营"
    },
    {
      id: "2",
      name: "加盟"
    },
    {
      id: "3",
      name: "直管"
    }
  ];
}
class TaskDetail {
  shopId = ""; // 店铺id
  price = ''; // 任务
  month = ''; // 月份
  marketId = ''; // 市场
}
class TaskExcel {
  date = ''; // 年月份
  file = {};
}
class Rules {
  shopId = [
    { required: true, message: "请选择门店", trigger: "change" }
  ];
  price = [
    { required: true, message: "请输入任务金额", trigger: "change" }
  ];
  month = [
    { required: true, message: "请选择月份", trigger: "change" }
  ];

}
export default {
  name: 'OrderList',
  components: {
    pagination
  },
  data() {
    return {
      search: new Search(), // 检索条件
      page: new Page(), // 分页
      rules: new Rules(), // 校验
      taskDetail: new TaskDetail(),
      taskExcel: new TaskExcel(),
      options: new Options(), //选项
      list: [], // 数据展示
      timeReachRate: '',
      shopCount: '',
      loading: false,
      exportS: false,
      taskFrame: false,
      taskFrame2: false,
      loading: false,
    }
  },
  computed: {
    tableData() {
      const res = this.list.reduce((res, item) => {
        item.maxnum = 0;
        if (item.list && item.list.length > 0) {
          const goods = item.list.map(goods => Object.assign({}, item, goods));
          goods[0].maxnum = item.list.length;
          res = res.concat(goods);
        } else {
          res.push(item);
        }
        return res;
      }, []);
      return res;
    },
    ...mapGetters(["userInfo"])
  },
  mounted() {
    let year = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    if (month.toString().length == 1) {
      month = '0' + month
    }
    let day = getLastDay(year, month)
    if (day.toString().length == 1) {
      day = '0' + day
    }
    this.search.start_time = year + '-' + month + '-01'
    this.search.end_time = year + '-' + month + '-' + day
    this.getList('search');
  },
  methods: {
    getList(option) {
      this.loading = true
      if (option === "search") this.page = new Page();
      readAdminShopAchievementStatistics({ ...this.search, ...this.page }).then(res => {
        this.list = res.data.list;
        this.page.total = res.data.dataCount;
        this.timeReachRate = res.data.timeReachRate;
        this.shopCount = res.data.shopCount;
        this.loading = false;
      });
    },
    // 门店查找
    remoteShop(e) {
      getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
        this.options.shopId = res.list;
      });
    },
    // 选择门店
    selectShop(e) {
      this.options.shopId.forEach(item => {
        if (e == item.shopId) {
          this.taskDetail.marketId = item.marketId;
        }
      });
    },

    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex <= 1) {
        return 'background:#CCFFFF;text-align:center'
      }
      if (columnIndex > 1 && columnIndex <= 3) {
        return 'background:rgb(199, 230, 202);text-align:center'
      }

      if (columnIndex > 3 && columnIndex <= 8) {
        return 'background:#FFCC99;text-align:center'
      }

      if (columnIndex == 9) {
        return 'background:#FFFF99;text-align:center'
      }
      if (columnIndex >= 10 && columnIndex <= 11) {
        return 'background:rgb(244, 221, 164);text-align:center'
      }
      if (columnIndex >= 12 && columnIndex <= 13) {
        return 'background:#e5c06f;text-align:center'
      }
      if (columnIndex >= 14) {
        return 'background:#f4dcdc;text-align:center'
      }

      return '';
    },
    rowClass({ row, column, rowIndex, columnIndex }) {
      return 'background:#E9EDEE;text-align:center'

    },
    clickBtn(row, type) {
      // console.log(type);
      const query = {

        type: type,
        //type: type == "amount"?2 : type == "developRate"?1 : 3,
        //type: type == "popularize"?2:1,
        shopId: row.shopId,
        start_time: this.search.start_time,
        end_time: this.search.end_time,
      };
      //console.log(query);
      this.$router.push({ name: "月度业绩明细", query });
    },

    // 关闭弹窗
    closeTask() {
      this.taskDetail = new TaskDetail();
      this.taskExcel = new TaskExcel()
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.hint("添加此月度任务");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    hint(text) {
      this.$confirm(`${text},是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.confirm();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    confirm() {
      shopTaskS({ ...this.taskDetail }).then(res => {
        console.log(this.taskDetail)
        this.$message({
          type: "success",
          message: res.msg
        });
        this.taskFrame = false;
        this.getList();
      });
    },
    importFile() {
      const formData = new FormData();
      formData.append('file', this.taskExcel.file);
      formData.append('date', this.taskExcel.date);
      importShopAchievementExcel(formData).then(res => {
        this.$message({
          type: "success",
          message: res.msg
        });
        this.taskFrame2 = false;
        this.getList();
      });
    },
    handleFileChange(event) {
      this.taskExcel.file = event.target.files[0];
      console.log(this.taskExcel);
    },
    // 导出表格
    async exportExcel() {
      this.exportS = true
      const { data } = await readAdminShopAchievementStatistics({
        ...this.search, ...this.page, export: 1
      });
      handleExportExcel(
        [
          '市场名称',
          '店面名称',
          '实收款金额',
          '转诊业绩',
          '疤痕业绩',
          '项目业绩',
          '青春痘业绩',
          '护肤品业绩',
          '业绩合计',
          '店面任务',
          '店面达成率',
          '市场任务',
          '市场达成',
          '市场达成率',
          '店面任务(基础)',
          '店面达成率(基础)',
          '市场任务(基础)',
          '市场达成率(基础)'
        ],
        [
          'marketName',
          'shopName',
          'amount',
          'referralMoney',
          'scarMoney',
          'projectMoney',
          'qcdMoney',
          'hfpMoney',
          'achievementMoney',
          'shopTask',
          'shopReachRate',
          'marketTask',
          'marketReach',
          'marketReachRate',
          'shopTaskBase',
          'shopReachRateBase',
          'marketTaskBase',
          'marketReachRateBase',
        ],
        data.list,
        '业绩达成统计'
      );
      this.exportS = false
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 13 || columnIndex === 14 || columnIndex === 15 || columnIndex === 16 || columnIndex === 17) {
        return {
          rowspan: row.maxnum,
          colspan: 1
        };
      } else {
        return {
          rowspan: 1,
          colspan: 1
        };
      }
    },
    getExcel() {
      window.open('http://burn.178590.com/excel/任务导入模板.xlsx');
    },
    renderHeader(h, { column }) {
      switch (column.label) {
        case "实收款": {
          return h(
            'div', [
            h('el-tooltip', {
              props: {
                effect: 'dark',
                content: '录入的收款单金额合计减去退款单金额合计',
                placement: 'top',
              },
            },
              [h('span', column.label)])
          ]
          )
        }
        case "转诊": {
          return h(
            'div', [
            h('el-tooltip', {
              props: {
                effect: 'dark',
                content: '转诊业绩：',
                placement: 'top',
              },
            },
              [h('span', column.label)])
          ]
          )
        }
        case "疤痕": {
          return h(
            'div', [
            h('el-tooltip', {
              props: {
                effect: 'dark',
                content: '收款单（不含店转）的疤痕实收金额【减去】退款单（不含店转）的疤痕实收金额【加上】门诊转到门店的疤痕实收金额【减去】退款单是店转的疤痕金额 【加上】收款单是店转的疤痕金额',
                placement: 'top',
              },
            },
              [h('span', column.label)])
          ]
          )
        }

		case "皮肤项目": {
		  return h(
		    'div', [
		    h('el-tooltip', {
		      props: {
		        effect: 'dark',
		        content: '皮肤项目',
		        placement: 'top',
		      },
		    },
		      [h('span', column.label)])
		  ]
		  )
		}

		case "身体项目": {
		  return h(
		    'div', [
		    h('el-tooltip', {
		      props: {
		        effect: 'dark',
		        content: '身体项目',
		        placement: 'top',
		      },
		    },
		      [h('span', column.label)])
		  ]
		  )
		}

        // case "项目合计": {
        //   return h(
        //     'div', [
        //     h('el-tooltip', {
        //       props: {
        //         effect: 'dark',
        //         content: '收款单（不含店转）的项目实收金额【减去】退款单（不含店转）的项目实收金额【加上】门诊转到门店的项目实收金额【减去】退款单是店转的项目金额【加上】收款单是店转的项目金额',
        //         placement: 'top',
        //       },
        //     },
        //       [h('span', column.label)])
        //   ]
        //   )
        // }

        case "青春痘": {
          return h(
            'div', [
            h('el-tooltip', {
              props: {
                effect: 'dark',
                content: '收款单（不含店转）的青春痘实收金额【减去】退款单（不含店转）的青春痘实收金额【加上】门诊转到门店的青春痘实收金额【减去】退款单是店转的青春痘金额【加上】收款单是店转的青春痘金额',
                placement: 'top',
              },
            },
              [h('span', column.label)])
          ]
          )
        }

        case "护肤品": {
          return h(
            'div', [
            h('el-tooltip', {
              props: {
                effect: 'dark',
                content: '收款单（不含店转）的护肤品实收金额【减去】退款单（不含店转）的护肤品实收金额【加上】门诊转到门店的护肤品实收金额【减去】退款单是店转的护肤品金额【加上】收款单是店转的护肤品金额',
                placement: 'top',
              },
            },
              [h('span', column.label)])
          ]
          )
        }


      }
    },
  }
}
</script>

<style lang="scss" scoped>
.reveal {
  text-align: right;
  padding: 20px 0;

  .peril {
    font-weight: bold;
  }
}

.footerBtn,
.btnBox {
  text-align: right;
}

.hand {
  cursor: pointer;
}
</style>
